<template>
  <div class="bg bg20">
    <div class="class--con">
      <class-but i-s-throw-out @forward="forward" @backOfff="backOfff"/>
      <div class="video">
        <video controls
               :src="videoUrl"
               >
          您的浏览器不支持Video标签
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
import service from "@/utils/axios";


export default {
  name: "cutePets",
  components: {ClassBut},
  data() {
    return {
      videoUrl:null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      let r = await service.get(`/dwart/an_teaching/hbk/v1/getAdvVideo?classId=${this.$route.query.classId}&type=1`);
      if ( r.url ) {
        this.videoUrl = r.url;
      }
    },
    forward() {
      this.$router.push({
        //视频展示
        name: 'PageSixteenth',
        query: this.$route.query,
      })
    },
    backOfff() {
      this.$router.back(-1)
      if ( this.$route.query.subjectName === '书法'  ) {
        localStorage.setItem('reportClass', 2)
      }else {
        localStorage.setItem('reportClass', 1)
      }
    },
  }
}
</script>
<style lang="less" scoped>
@import "../ConferenceItem/reportClass.less";

.video {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    width: 1349px;
    height: 778px;
    border: 20px solid #ffe16c;
    border-radius: 20px;
  }
}
</style>
